import { ref } from 'vue';
import type { Ref } from 'vue';
interface UseSelectParams {
  type: string;
  answer: Ref;
  modelName: string;
  max: number;
  disabled: boolean;
  emit: any;
  emitEvent: string;
}

export function useSelect({
  type,
  answer,
  max,
  disabled,
  emit,
  emitEvent,
}: UseSelectParams) {
  const isRadio = ref(type === 'radio');
  const isRange = ref(type === 'range');

  const handleRadioSelection = (id: string, editable: boolean) => {
    answer.value[0] = editable ? { id, text: '' } : id;
    emit(emitEvent, answer.value);
  };

  const handleCheckboxSelection = (id: string, editable: boolean) => {
    const index = answer.value.findIndex(item =>
      editable ? item.id === id : item === id,
    );
    const value = editable ? { id, text: '' } : id;
    if (index >= 0) answer.value.splice(index, 1);
    else if (max !== 1) {
      if (answer.value.length < max) {
        answer.value.push(value);
      }
    } else {
      answer.value = [value];
    }
    emit(emitEvent, answer.value);
  };

  const handleSelection = (e: any, editable = false) => {
    if (disabled) return;
    if (isRadio.value || isRange.value) handleRadioSelection(e, editable);
    else handleCheckboxSelection(e, editable);
  };

  const handleInput = (id: any, text: any) => {
    const index = answer.value.findIndex(item => item.id === id);
    if (index !== -1) {
      answer.value[index].text = text;
    }
  };

  const getIsSelected = (
    id: string | number,
    type = isRadio.value ? 'radio' : null,
  ) => {
    if (type === 'radio' || type === 'range') {
      return answer.value[0] == id || answer.value[0]?.id == id;
    } else {
      return (
        answer.value.find((element: any) =>
          typeof element == 'object' ? element.id == id : element === id,
        ) !== undefined
      );
    }
  };

  return {
    isRadio,
    answer,
    handleRadioSelection,
    handleCheckboxSelection,
    handleSelection,
    getIsSelected,
    handleInput,
  };
}
