<template>
  <div class="flex flex-wrap">
    <div v-if="!isPreview" class="flex gap-3">
      <div v-for="(option, index) in options" :key="option.id" class="">
        <PollCard
          :key="option.id"
          :option="option"
          :is-active="false"
          :present-icon="true"
          :placeholder="
            index === 0
              ? t('blocksSettings.poll.support')
              : t('blocksSettings.poll.reject')
          "
          @title-changed="title => (option.title = title)">
          <template #icon>
            <ThumbsUp v-if="index === 0" />
            <ThumbsDown v-else />
          </template>
        </PollCard>
      </div>
    </div>

    <div v-else class="flex items-center gap-3">
      <div
        v-for="(option, index) in options"
        :key="option.id"
        class="flex justify-center">
        <Card
          style="overflow: hidden; box-shadow: none"
          class="h-50 w-50 bg-secondary-75 hover:cursor-pointer flex justify-center p-7 border-1 border-solid border-secondary-250 hover:border-primary-500"
          :style="{ border: model[0] === option.id ? '1px solid #0093E5' : '' }"
          :class="{
            '!bg-primary-200  ': model[0] === option.id,
          }"
          :pt="{
            body: {
              class: ['mt-4'],
            },
          }"
          @click="model = [option.id]">
          <template #header>
            <span class="flex justify-center items-center">
              <ThumbsUp v-if="index == 0" />
              <ThumbsDown v-else />
            </span>
          </template>
          <template #content>
            <p
              class="m-0 font-simplerBold flex justify-center line-clamp-2 text-center"
              style="
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              ">
              {{ option.title }}
            </p>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import PollCard from './PollCard.vue';
import ThumbsUp from '../../../src/assets/ThumbsUp.svg?component';
import ThumbsDown from '../../../src/assets/ThumbsDown.svg?component';

import Card from 'primevue/card';
import type { PollOption } from '../types/poll.ts';
import { useI18n } from 'vue-i18n';

import { useVModel } from '@vueuse/core';

const props = defineProps({
  type: {
    type: String,
    default: 'radio',
  },
  options: {
    type: Array as PropType<PollOption[]>,
    default: () => [],
  },
  isPreview: {
    type: Boolean,
    default: true,
  },
  modelValue: {
    type: Array<String>,
    default: () => [],
  },
  addOptionEnable: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();

const model = useVModel(props, 'modelValue');
</script>
