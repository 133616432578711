<template>
  <PVSplitter
    :style="{ minHeight: minHeight + 'px' }"
    :layout="layout"
    class="!rounded-lg"
    @resize="handleResize">
    <PVSplitterPanel
      ref="mainPanel"
      :size="defaultSize"
      :min-size="contnetMinSize"
      class="flex flex-1 items-start justify-start p-5 main border-0 rounded-none"
      style="height: auto">
      <slot></slot>
    </PVSplitterPanel>
    <PVSplitterPanel
      :size="100 - defaultSize"
      class="flex flex-col gap-3"
      :class="{
        'vertical-shadow': direction === 'vertical',
        'horizontal-shadow': direction === 'horizontal',
      }">
      <div v-if="showToggle" class="flex justify-end" @click="toggleLayout">
        <PictureInPicture2 />
      </div>
      <p class="text-secondary-900 pt-3 px-5 font-simplerBold">
        {{ sideTitle }}
      </p>
      <div
        class="overflow-y-auto scrollbar-box p-5 pt-0 h-full"
        :style="{
          maxHeight: sideMaxHeight + 'px',
          minHeight: minHeight + 'px',
        }">
        <slot name="side" />
      </div>
    </PVSplitterPanel>
  </PVSplitter>
</template>
<script setup lang="ts">
import PVSplitter from 'primevue/splitter';
import PVSplitterPanel from 'primevue/splitterpanel';
import { ref } from 'vue';
import { useResizeObserver } from '@vueuse/core';
import type { PropType } from 'vue';
import { PictureInPicture2 } from 'lucide-vue-next';
enum Layout {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

const props = defineProps({
  direction: {
    type: String as PropType<Layout>,
    default: 'horizontal',
  },
  showToggle: {
    type: Boolean,
    default: false,
  },
  minHeight: {
    type: Number,
    default: 100,
  },
  contnetMinSize: {
    type: Number,
    default: 50,
  },
  defaultSize: {
    type: Number,
    default: 75,
  },
  sideTitle: {
    type: String,
    default: 'בנק תשובות',
  },
});

const layout = ref(props.direction); // Default to 'vertical'

// Toggle the layout
const toggleLayout = () => {
  layout.value =
    layout.value === Layout.Vertical ? Layout.Horizontal : Layout.Vertical;
};

const mainPanel = ref<HTMLElement | null>(null);
const sideMaxHeight = ref(0);

useResizeObserver(mainPanel, () => {
  handleResize();
});

const handleResize = () => {
  if (mainPanel.value) {
    //@ts-ignore
    const firstChild = mainPanel.value.$el
      .firstElementChild as HTMLElement | null;
    const firstChildHeight = firstChild
      ? firstChild.getBoundingClientRect().height
      : props.minHeight;
    sideMaxHeight.value = Math.floor(
      Math.max(props.minHeight, firstChildHeight - 20),
    );
  }
};
</script>

<style>
/* Tailwind custom scrollbar */
.scrollbar-box::-webkit-scrollbar {
  width: 6px;
  @apply ms-1 ps-1;
}

.scrollbar-box::-webkit-scrollbar-thumb {
  @apply bg-secondary-300 rounded-lg;
}

.scrollbar-box::-webkit-scrollbar-track {
  @apply bg-secondary-100;
}

.horizontal-shadow {
  @apply border-s rounded-e-lg shadow-[inset_0px_0px_4px_0px_rgba(0,0,0,0.12)];
}

.vertical-shadow {
  @apply border-t rounded-b-lg shadow-[inset_0px_0px_4px_0px_rgba(0,0,0,0.12)];
}
</style>
