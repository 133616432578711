<template>
  <div
    class="h-full w-full flex items-center flex-col overflow-y-auto"
    :class="[
      size == 'full' || smallerThanMd ? 'w-full' : 'w-[calc(75vw-22rem)]',
    ]">
    <div class="flex justify-between items-center w-full pb-2">
      <div class="flex items-center">
        <button
          v-if="persistent"
          aria-label="close"
          type="button"
          class="pl-4"
          @click="$emit('close')">
          <X />
        </button>
        <div class="text-xl text-dark font-anomaliaBold whitespace-pre-line">
          {{ caption }}
        </div>
      </div>
      <BaseButton
        v-if="transcription"
        color="blue"
        outline
        class="flex gap-1 items-center p-2 border border-primary rounded-lg font-simplerBold"
        @click="handleShowTranscription">
        <div class="flex items-center gap-2">
          <PlayCircle v-if="showTranscription" class="stroke-primary" />
          <FileType v-else class="stroke-primary" />
          <span class="text-primary">
            {{ showTranscription ? 'חזרה לסרטון' : 'לצפייה בטקסט' }}
          </span>
        </div>
      </BaseButton>
    </div>
    <div class="flex w-full h-full">
      <div
        v-show="!showTranscription"
        class="relative gap-2 flex flex-col items-center h-full aspect-video w-full max-w-4xl rounded-2xl overflow-hidden">
        <video
          v-if="type === 'custom'"
          :id="`video-player-custom-${id}`"
          ref="videoElement"
          :controls="controls"
          playsinline
          class="bg-black h-full aspect-video"
          :style="{ width: '100%' }"
          :playbackRate="videoSpeed"
          @canplay="onReady"/>
        <div
          v-if="!controls"
          class="absolute inset-0 flex items-center justify-center z-10 cursor-pointer"
          @click="togglePlay">
          <PlayCircle
            v-if="type !== 'custom' && !playing"
            class="text-white w-12 h-12"/>
        </div>
        <div
          v-if="type === 'custom'"
          class="cursor-pointer rounded absolute bottom-0 right-16 z-10 h-12 w-12"
          @click="onVideoSpeedClick()"/>
        <div
          v-if="type === 'vimeo' && inScope"
          :id="`vimeo-player-${vimeoId}`"
          ref="vimeoRef"
          class="aspect-video w-full max-w-4xl"></div>

        <YoutubePlayer
          v-if="type === 'youtube' && youtubeReady && inScope"
          ref="youtubeRef"
          class="aspect-video w-full max-w-4xl"
          :src="src as any"
          width="100%"
          height="100%"
          :vars="playerVars"
          @ready="onReady"
          @state-change="handleYoutubeState"/>
        <loading-overlay
          :active="!ready"
          :can-cancel="false"
          :is-full-page="false"
          :opacity="0.8"
          :z-index="9999999"
          color="#fff"
          background-color="#333"/>
      </div>
      <div v-show="showTranscription" class="py-8 overflow-y-auto h-full">
        <TiptapContent v-if="editor" :editor="editor" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  computed,
  ref,
  watch,
  watchEffect,
  onMounted,
  PropType,
  nextTick,
} from 'vue';
import { FileType, PlayCircle, X } from 'lucide-vue-next';
import { BaseButton } from '@/components';
import { usePresence } from '@/apps/slideshow/usePresence';
import { screens as breakpointsTailwind } from '../../../packages/themes/amit-theme';
import {
  useInterval,
  useThrottleFn,
  useBreakpoints,
  useElementVisibility,
} from '@vueuse/core';
import cloudinaryPlayer from 'cloudinary-video-player';
import 'cloudinary-video-player/cld-video-player.min.css';
import { videoStreamUrl } from '@/utils/helpers';
import LoadingOverlay from 'vue-loading-overlay';
import 'cloudinary-video-player/chapters';
import YoutubePlayer from 'vue3-youtube';
import { useYoutubeApi } from '@/composables/useYoutubeApi';
import { nanoid } from 'nanoid';
import VimeoPlayer, { Player } from '@vimeo/player';
import TiptapContent from '@amit/tiptap/src/TiptapContent.vue';
import { useEditor } from '@amit/tiptap';
const { getEditor, createEditor } = useEditor();
const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  caption: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'none',
  },
  transcription: {
    type: String,
    default: '',
  },
  callStopVideo: {
    type: Boolean,
    default: false,
  },
  controls: {
    type: Boolean,
    default: true,
  },
  loop: {
    type: Boolean,
    default: false,
  },
  persistent: {
    type: Boolean,
    default: false,
  },
  autoPlay: {
    type: Boolean,
    default: true,
  },
  indicators: {
    type: Array as PropType<number[]>,
    default: () => [],
  },
  type: {
    type: String,
    default: 'custom',
  },
  startTime: {
    type: Number,
    default: 0,
  },
  endTime: {
    type: Number,
    default: 0,
  },
});
enum YoutubeState {
  // UNSTARTED = -1,
  ENDED = 0,
  PLAYING = 1,
  PAUSED = 2,
  // BUFFERING = 3,
  // CUED = 5
}
let vimeoPlayer: Player | null = null;

const playbackRates = [0.5, 1, 1.5, 2];
const vimeoId = ref(nanoid());
const vimeoRef = ref();

const breakpoints = useBreakpoints(breakpointsTailwind);
const smallerThanMd = breakpoints.smaller('md');
const { youtubeReady } = useYoutubeApi();
const ready = ref(false);
const ended = ref(false);
const videoPlayer = ref<any>(null);
const youtubeRef = ref();
const id = ref(nanoid());
const videoSpeedCtrlIndex = ref(0);
const videoSpeed = computed(() => playbackRates[videoSpeedCtrlIndex.value]);
const playing = ref(false);
const videoElement = ref();
const showTranscription = ref(false);
const youtubePlaying = ref(false);

const { onPause, onResume, slideId, scope } = usePresence();
const inScope = computed(() => scope.value === slideId.value);
const videoIsVisible = useElementVisibility(videoElement);

const transformedUrl = props.autoPlay
  ? videoStreamUrl(props.src)
  : props.src.trim();

onPause(async () => {
  if (props.type === 'custom') {
    videoPlayer.value?.pause();
  } else if (props.type === 'youtube') {
    await youtubeRef.value.player.pauseVideo();
  } else if (props.type === 'vimeo') {
    vimeoPlayer?.pause();
  }
});
onResume(() => {
  playVideo();
});

const chapters = computed(() => {
  return props.indicators.reduce((acc, time) => {
    acc[time] = '';
    return acc;
  }, {});
});

const onReady = () => {
  ready.value = true;
};

const togglePlay = async () => {
  if (playing.value) {
    stopVideo();
  } else {
    playVideo();
  }
};

const handleYoutubeState = state => {
  youtubePlaying.value = state.data === YoutubeState.PLAYING;
  playing.value = youtubePlaying.value;
  if (state.data === YoutubeState.ENDED) {
    ended.value = true;
    youtubePlaying.value = false;
  }
};

onMounted(() => {
  if (props.type === 'custom') {
    const playerId = 'video-player-custom-' + id.value;
    const playerElement = document.getElementById(playerId);

    if (!playerElement) return;

    videoPlayer.value = cloudinaryPlayer.videoPlayer(playerId, {
      showLogo: false,
      playbackRates,
      volume: 0.8,
      playedEventTimes: props.indicators,
    });

    videoPlayer.value.source(transformedUrl, {
      chapters: chapters.value,
    });

    videoPlayer.value.on('play', () => {
      playing.value = true;
    });

    videoPlayer.value.on('ended', () => {
      ended.value = true;
      playing.value = false;
    });

    videoPlayer.value.on('pause', () => {
      playing.value = false;
    });

    videoPlayer.value.on('timeplayed', event => {
      emit('current-time', {
        time: event.eventData.time,
        seconds: Math.floor(event.eventData.time),
        force: true,
      });
    });
  }
});

watch(
  () => vimeoRef.value,
  async () => {
    if (vimeoRef.value && props.type === 'vimeo') {
      const options = {
        url: props.src,
        autoplay: props.autoPlay,
        loop: props.loop,
        controls: props.controls,
      };
      await nextTick();
      vimeoPlayer = new VimeoPlayer(vimeoRef.value, options);

      vimeoPlayer.on('timeupdate', data => {
        onTimeupdate({ seconds: data.seconds });
      });
      vimeoPlayer.on('play', () => {
        playing.value = true;
      });

      vimeoPlayer.on('pause', () => {
        playing.value = false;
      });
      vimeoPlayer.on('loaded', () => {
        onReady();
        adaptVimeo();

        if (props.startTime > 0) {
          vimeoPlayer.setCurrentTime(props.startTime);
        }
      });
      vimeoPlayer.on('ended', () => {
        ended.value = true;
      });
    }
  },
  { immediate: true },
);

const adaptVimeo = () => {
  let iframe = vimeoRef.value.querySelector('iframe');
  iframe.setAttribute('width', '100%');
  iframe.setAttribute('height', '100%');
};

const TIME_WINDOW = 1;
const lastTriggerTime = ref<number | null>(null);

const onTimeupdate = useThrottleFn(({ seconds }) => {
  const currentSecond = Math.floor(seconds);
  const matchingIndicator = props.indicators.find(indicator => {
    return Math.abs(indicator - seconds) < TIME_WINDOW;
  });

  // Only emit if we found a matching indicator and haven't triggered recently
  if (
    matchingIndicator &&
    (!lastTriggerTime.value ||
      Math.abs(lastTriggerTime.value - seconds) > TIME_WINDOW)
  ) {
    lastTriggerTime.value = seconds;
    emit('current-time', {
      time: seconds,
      seconds: currentSecond,
      force: true,
    });
  }
}, 500);

// const youtubeVideoId = computed(() => {
//   const url = props.src;
//   return url ? new URL(url).searchParams.get('v') : '';
// });

if (props.type === 'youtube') {
  useInterval(10, {
    callback: () => {
      if (youtubePlaying.value && youtubeRef.value) {
        let seconds = youtubeRef.value.getCurrentTime();
        onTimeupdate({ seconds });
      }
    },
  });
}

const emit = defineEmits([
  'video-next-enabled',
  'set-watch-time',
  'close',
  'current-time',
]);

const playVideo = async () => {
  try {
    lastTriggerTime.value = null;
    if (props.type === 'custom') {
      videoPlayer.value.play();
      playing.value = true;
    }
    if (props.type === 'youtube') {
      await youtubeRef.value.playVideo();
      playing.value = true;
    }
    if (props.type === 'vimeo') {
      vimeoPlayer?.play();
      playing.value = true;
    }
  } catch (err) {
    console.log('error', err);
  }
};

const playerVars = computed(() => ({
  autoplay: Number(props.autoPlay),
  controls: Number(props.controls),
  loop: Number(props.loop),
  start: props.startTime,
  end: props.endTime,
}));

const stopVideo = async () => {
  if (props.type === 'custom') {
    videoPlayer.value.pause();
  }
  if (props.type === 'youtube') {
    youtubeRef.value.pauseVideo();
  }
  if (props.type === 'vimeo') {
    vimeoPlayer?.pause();
  }
  playing.value = false;
};

const onVideoSpeedClick = () => {
  videoSpeedCtrlIndex.value =
    (videoSpeedCtrlIndex.value + 1) % playbackRates.length;
};

const handleShowTranscription = () => {
  if (!showTranscription.value) {
    stopVideo();
  }
  showTranscription.value = !showTranscription.value;
  emit('video-next-enabled');
};

watch(ended, value => {
  if (value) {
    emit('video-next-enabled');
  }
});

watchEffect(() => {
  if (!props.src) {
    ready.value = true;
  }
  if (inScope.value && props.autoPlay && videoIsVisible.value) {
    playVideo();
  }
});

watch(
  () => props.callStopVideo,
  callStopVideo => {
    if (callStopVideo) {
      stopVideo();
    }
  },
);

createEditor({
  id: id,
  editable: false,
  // direction: props.locale != 'he' ? 'ltr' : 'rtl',
  model: props.transcription,
});

const editor = getEditor(id);
</script>

<style>
.cld-video-player .vjs-control-bar {
  padding-left: 10px;
  padding-right: 10px;
  background-color: black !important;
}

.video-js .vjs-fullscreen-control .vjs-icon-placeholder {
  display: none;
}

.video-js .vjs-modal-dialog,
.vjs-button > .vjs-icon-placeholder:before,
.vjs-modal-dialog .vjs-modal-dialog-content {
  position: relative;
}

.video-js .vjs-fullscreen-control {
  background-image: url('@/assets/full-screen.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 20px;
  width: 20px;
  margin-top: 12px;
}

.cld-video-player .vjs-playback-rate-value {
  font-size: 16px !important;
  color: white;
  background-color: #ffffff33;
  border-radius: 8px;
  padding-bottom: 2px;
  height: 28px;
  width: 33px;
  margin-top: 5px;
}

.cld-video-player .vjs-control-bar .vjs-progress-holder {
  background-color: #495465;
  height: 6px;
}

.cld-video-player .vjs-chapter-marker::before {
  content: '';
  background-image: url('@/assets/help-circle.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 20px;
  width: 20px;
  position: absolute;
  top: -25px;
  left: -6px;
  border-radius: 10px;
}

.cld-video-player .vjs-chapter-marker {
  width: 8px;
  background-color: #ffffff;
}
</style>
