<template>
  <div
    class="tags-input w-full flex flex-col !gap-2 overflow-y-auto scrollbar-box px-2 pt-3 border overflow-y-auto rounded-lg transition"
    :class="{
      'active bg-primary-100 border-primary-600': !disabled && isActive,
      'border-secondary-250 bg-secondary-100':
        disabled || !isActive || !(highlighted && !tags.length),
      '!bg-primary-50 !border-primary-600 animate-pulse':
        highlighted && !tags.length,
    }"
    :style="{ height }">
    <p v-if="title" class="text-secondary-900 px-1 font-semibold">
      {{ title }}
    </p>
    <div class="h-full flex-1" @click="inputFocus">
      <VueDraggable
        v-if="isDraggable"
        v-model="tags"
        :group="group"
        item-key="id"
        :sort="false"
        :disabled="disabled"
        class="h-full w-full flex flex-col gap-2"
        @dragover.prevent="setIsActive(true)"
        @dragleave="setIsActive(false)"
        @dragend="setIsActive(false)"
        @drop="emit('drop')"
        @add="emit('add')">
        <template #footer>
          <TagInput
            v-for="(option, index) in options"
            :key="option.id || index"
            :title="option.text"
            :disabled="true"
            color="default"
            :removable="false"/>
        </template>
        <template #item="{ index }">
          <TagInput
            :title="tags[index].title"
            :removable="false"
            :color="tags[index].color"
            class="cursor-pointer"/>
        </template>
      </VueDraggable>

      <div
        v-else
        class="z-20 flex flex-col rounded-xl h-50 overflow-y-auto w-full h-full p-2"
        :class="{ '!pt-0': !title }">
        <div
          class="tag-input w-full flex flex-wrap items-start justify-start gap-2">
          <TagInput
            v-for="(tag, index) in tags"
            :key="tag.id || index"
            :title="tags[index].title"
            :color="color"
            :class="{ duplicate: tags[index].title === duplicate }"
            :removable="!disabled"
            @remove-tag="removeTag(index)"/>
        </div>
        <div class="tag-input flex-1 flex justify-start items-start">
          <input
            ref="input"
            v-model="newTag"
            type="text"
            autocomplete="off"
            :placeholder="`הוספת ${placeholder}`"
            class="w-full bg-transparent z-10 border-0"
            :class="{ 'h-10': tags.length }"
            :disabled="disabled"
            @keydown.enter="addTag(newTag)"
            @blur="addTag(newTag)"
            @keydown.prevent.tab="addTag(newTag)"
            @keydown.delete="newTag.length || removeTag(tags.length - 1)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TagInput from './TagInput.vue';
import { useVModel } from '@vueuse/core';
import type { PropType } from 'vue';
import { provide, ref } from 'vue';
import type { TagType } from '@/components/TagsInput/types';
import { map } from 'lodash';
import type { ITag } from '@/types/interfaces';
import VueDraggable from 'vuedraggable';

const emit = defineEmits(['add', 'drop']);
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array as PropType<{ id: string; text: string }[]>,
    default: () => [],
  },
  modelValue: {
    type: Array as PropType<ITag[]>,
    default: () => [],
  },
  color: {
    type: String as PropType<TagType>,
    default: 'default',
  },
  isDraggable: {
    type: Boolean,
    default: false,
  },
  height: {
    type: String,
    default: '150px',
  },
  group: {
    type: String,
    default: 'answers',
  },
  highlighted: {
    type: Boolean,
    default: false,
  },
});
const tags = useVModel(props, 'modelValue');

const input = ref();
const inputFocus = () => {
  if (!props.isDraggable) input.value.focus();
};
// Tags
// const tags = ref(props.modelValue);
const newTag = ref('');

const addTag = tag => {
  if (!tag) return;
  if (map(tags.value, 'text').includes(tag)) {
    handleDuplicate(tag);
    return;
  }
  tags.value.push({ title: tag, id: Math.random().toString(36).substring(7) });
  newTag.value = '';
};
const removeTag = (index: number) => {
  tags.value.splice(index, 1);
};

// handling duplicates
const duplicate = ref(null);
const handleDuplicate = tag => {
  duplicate.value = tag;
  setTimeout(() => (duplicate.value = null), 1000);
  newTag.value = '';
};

const isActive = ref(false);
const setIsActive = val => {
  isActive.value = val;
};

// Expose the method to parent components
defineExpose({ setIsActive });

provide('color', props.color);
</script>
<style>
@keyframes shake {
  10%,
  90% {
    transform: scale(0.9) translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: scale(0.9) translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: scale(0.9) translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: scale(0.9) translate3d(4px, 0, 0);
  }
}
.duplicate {
  animation: shake 1s;
}
.tag-text {
  overflow-wrap: anywhere;
}
</style>
