<template>
  <FormControl :title="title" :sub-title="subTitle">
    <component
      :is="showBankButton && !review ? WordBank : 'div'"
      :side-title="getLocalizedText('word_bank')"
      :class="{ 'bg-white p-5': review }">
      <template #default>
        <div ref="bankTextElement" class="grid gap-2">
          <div
            v-for="(line, lineIndex) in transformedArray"
            :key="lineIndex"
            class="flex flex-wrap gap-2 items-center">
            <div
              v-for="(word, wordIndex) in line"
              :key="wordIndex"
              class="flex flex-wrap gap-2 items-center">
              <div v-if="isBlank(word)">
                <VueDraggable
                  v-if="format === 'dnd'"
                  v-model="model[word.id]"
                  :group="getDragGroup(word)"
                  item-key="id"
                  :sort="false"
                  class="min-w-30 h-[34px] flex flex-col border rounded-md transition"
                  :class="{
                    'active bg-primary-100 border-primary-600':
                      activeBlank === word.id,
                    'border-secondary-250 bg-secondary-100':
                      activeBlank !== word.id,
                  }"
                  @dragover="activeBlank = word.id"
                  @dragenter="activeBlank = word.id"
                  @dragstart="activeBlank = ''"
                  @add="activeBlank = ''"
                  @start="onDragStart(word.id)"
                  @end="onDragEnd"
                  @drop.prevent="onDragEnd">
                  <template #item="{ index }">
                    <div
                      class="h-8 flex items-center bg-white rounded-md px-2 gap-1 hover:cursor-pointer"
                      :class="{ 'w-full': draggableBlankKey !== word.id }"
                      @drag="onDragMove">
                      <button @click="removeElement(index, word.id)">
                        <CloseIcon class="h-4" />
                      </button>
                      <p>
                        {{ model[word.id][index] }}
                      </p>
                    </div>
                  </template>
                </VueDraggable>
                <BaseDropdown
                  v-else-if="format === 'select'"
                  fixed
                  placement="bottom"
                  :text="model[word?.id][0]">
                  <template #trigger>
                    <BaseButton color="dark" outline class="min-w-20">
                      <div class="flex justify-between gap-2">
                        <div class="grow">{{ model[word?.id][0] }}</div>
                        <ChevronDown :size="16" class="self-end" />
                      </div>
                    </BaseButton>
                  </template>
                  <ListGroup>
                    <ListGroupItem
                      v-for="(option, optionIndex) in word?.options"
                      :key="optionIndex"
                      @click="model[word?.id][0] = option" :dir="rtl ? 'rtl' : 'ltr'">
                      {{ option }}
                    </ListGroupItem>
                  </ListGroup>
                </BaseDropdown>
                <input
                  v-else-if="format === 'input'"
                  v-model="model[word.id][0]"
                  type="text"
                  class="rounded-lg bg-secondary-75 p-2 caret-primary focus:ring-0"/>
              </div>
              <p v-else class="leading-[2]" v-html="word" />
            </div>
          </div>
        </div>
      </template>
      <template #side>
        <BankBanner
          v-model="optionsRef"
          group-name="fill_in_blanks_answers"
          @hidden="closeBank"
          @drop="activeBlank = ''"/>
      </template>
    </component>
  </FormControl>
</template>

<script setup lang="ts">
import BankBanner from '@/components/BankBanner/BankBanner.vue';
import BaseDropdown from '@/components/Dropdown/BaseDropdown.vue';
import ListGroup from '@/components/ListGroup/ListGroup.vue';
import ListGroupItem from '@/components/ListGroup/components/ListGroupItem/ListGroupItem.vue';
import BaseButton from '@/components/Button/BaseButton.vue';
import { watch, computed, ref, watchEffect } from 'vue';
import { useElementVisibility, useVModel } from '@vueuse/core';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import VueDraggable from 'vuedraggable';
import type { IBlanksModel } from '@/types/interfaces';
import { X as CloseIcon, ChevronDown } from 'lucide-vue-next';
import { uniq } from 'lodash';
import WordBank from '@/components/WordBank/WordBank.vue';
import { useReveal } from '@/apps/slideshow/useReveal';
import { scrollParent } from '@/utils.ts';



const closeBank = () => {
  showBank.value = false;
};
interface FillBlanksProps {
  title?: string;
  subTitle?: string;
  text: string;
  format: string;
  options?: Array<any>;
  blanks?: IBlanksModel[];
  modelValue?: Record<string, string[]>;
  locale?: string;
  review?: boolean;
  rtl?: boolean;
}

const props = withDefaults(defineProps<FillBlanksProps>(), {
  title: '',
  subTitle: '',
  options: () => [],
  blanks: () => [],
  locale: 'he',
  modelValue: () => ({}),
  review: false,
  rtl: true,
});

const { getLocalizedText } = useReveal();

const emit = defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue', emit);
const draggableBlankKey = ref('');
const activeBlank = ref('');
const drag = ref(false);
const bankTextElement = ref();
const targetIsVisible = useElementVisibility(bankTextElement);
const showBank = ref(false);
const showBankButton = computed(() => props.format === 'dnd');

watchEffect(() => {
  showBank.value = targetIsVisible.value;
});

const optionsRef = ref();
const cleanedText = computed(() => {
  let text = props.text
    .replace(/<p[^>]*>/g, '\n')
    .replace(/<\/p>/g, '')
    .replace(/<ul[^>]*>/g, '\n<ul>\n')
    .replace(/<\/ul>/g, '\n</ul>\n')
    .replace(/<ol[^>]*>/g, '\n<ol>\n')
    .replace(/<\/ol>/g, '\n</ol>\n');

  let listCounter = 1;

  text = text.replace(/<li[^>]*>(.*?)<\/li>/gs, (_, content, offset, input) => {
    const isOrdered = /<ol[^>]*>/.test(input.slice(0, offset));
    const bullet = isOrdered ? `${listCounter++}.` : '•';
    return `${bullet} ${content.replace(/\n/g, ' ')}\n`;
  });

  return text.split('\n').filter(line => line.trim() !== '');
});

const splitText = computed(() => {
  return cleanedText.value.map(line =>
    line.split(/(<blank.*?>.*?<\/blank>|<blank.*?\/>)/g),
  );
});

const isBlank = word => typeof word === 'object';
const shuffleArray = array => {
  return array.sort(() => Math.random() - 0.5);
};

watchEffect(() => {
  const allOptions = shuffleArray([
    ...props.blanks.flatMap(blank => [
      ...blank.matches,
      ...(blank?.fillers ?? []),
    ]),
    ...props.options,
  ]);
  const selectedOptions = Object.values(model.value).flat();

  selectedOptions.forEach(option => {
    const index = allOptions.indexOf(option);
    if (index !== -1) {
      allOptions.splice(index, 1);
    }
  });

  optionsRef.value = allOptions;
});

const getDragGroup = word =>
  model.value[word.id]?.length < 1 || draggableBlankKey.value === word.id
    ? 'fill_in_blanks_answers'
    : `answer${word.id}`;

const onDragStart = wordId => {
  drag.value = true;
  draggableBlankKey.value = wordId;
};

const onDragEnd = () => {
  drag.value = false;
  draggableBlankKey.value = '';
  activeBlank.value = '';
};

const removeElement = (index, wordId) => {
  const element = model.value[wordId].splice(index, 1)[0];
  optionsRef.value.push(element);
};

const transformedArray = computed(() => {
  return splitText.value.map((line: any) => {
    return line.map((word: any) => {
      const match = word.match(/<blank\s+id="([^"]+)"(?:>([^<]*)<\/blank>)?/);
      if (match && match[1]) {
        const id = match[1];
        const blank = props.blanks.find(blank => blank.id === id);
        return {
          id,
          options: shuffleArray(
            uniq([
              ...(blank?.matches ?? []),
              ...(blank?.fillers ?? []),
              ...props.options,
            ]),
          ),
        };
      }
      return word;
    });
  });
});

watch(
  () => props.modelValue,
  () => {
    props.blanks.forEach(blank => {
      if (!model.value[blank.id]) {
        model.value[blank.id] = [];
      }
    });
  },
  { immediate: true },
);

const onDragMove = event => {
  scrollParent(document.querySelector('section.present > .r-stack'), event);
};
</script>

<style scoped>
.hover-bg:hover {
  background-image: url('@/assets/plusBgPrimary.svg');
}
.bg {
  background-image: url('@/assets/plusGray.svg');
}
</style>
