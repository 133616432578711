<template>
  <FormControl :title="title" :sub-title="subTitle">
    <div
      ref="playerContainer"
      class="h-full w-full flex"
      :style="{ padding: `${padding}px` }"
      :class="containerClasses">
      <div
        v-if="type === 'website'"
        class="full-screen-button cursor-pointer absolute bottom-3 left-3 ext-white p-3 rounded-md bg-white z-[1000000] full-screen-shadow hover:scale-[1.1]"
        :class="{ hidden: !isPreview }"
        :title="fullScreenText"
        tabindex="0"
        @click="toggle">
        <Minimize2
          v-if="isFullscreen"
          :size="20"
          class="stroke-secondary-900"/>
        <Maximize2 v-else :size="20" class="stroke-secondary-900" />
      </div>

      <YoutubePlayer
        v-if="type === 'youtube' && youtubeReady"
        ref="youtubeRef"
        class="aspect-video w-full max-w-4xl"
        :src="videoId || (src as any)"
        :width="width"
        :height="height"
        :vars="{
          controls: Number(controls),
          loop: Number(loop),
        }"
        @state-change="handleYoutubeState"/>

      <div
        v-if="type === 'vimeo'"
        :id="`vimeo-player-${vimeoId}`"
        ref="vimeoRef"
        class="aspect-video w-full max-w-4xl"></div>
      <iframe
        v-if="type === 'website' && inScope"
        ref="iframeRef"
        allow="fullscreen"
        :src="src"
        :height="isFullscreen ? '100%' : height"
        :width="width"
        :title="title"
        @load="loading = false"/>
    </div>
    <loading-overlay
      v-model:active="loading"
      :can-cancel="false"
      :is-full-page="false"
      :height="30"/>
  </FormControl>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted, nextTick } from 'vue';
import classNames from 'classnames';
import YoutubePlayer from 'vue3-youtube';
import { useFullscreen, useInterval, useThrottleFn } from '@vueuse/core';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import { usePresence } from '@/apps/slideshow/usePresence';
import LoadingOverlay from 'vue-loading-overlay';
import { useYoutubeApi } from '@/composables/useYoutubeApi';
import { nanoid } from 'nanoid';
import VimeoPlayer, { Player } from '@vimeo/player';
import { Maximize2, Minimize2 } from 'lucide-vue-next';
import { useReveal } from '@/apps/slideshow/useReveal.ts';
const vimeoId = ref(nanoid());

const playerContainer = ref<HTMLElement | null>(null);
const { isFullscreen, toggle } = useFullscreen(playerContainer);

type PlacementType = 'center' | 'start' | 'end' | 'stretch';
type BorderType = 'none' | 'primary';
type FrameType = 'youtube' | 'vimeo' | 'website';

const placementClasses: Record<PlacementType, string> = {
  center: 'justify-center',
  start: 'justify-start',
  end: 'justify-end',
  stretch: 'justify-stretch',
};
const borderClasses: Record<BorderType, string> = {
  none: 'border-none',
  primary: 'border-2 border-primary',
};
interface IProps {
  src: string;
  type?: FrameType;
  height?: string;
  placement?: PlacementType;
  border?: BorderType;
  padding?: number;
  width?: string;
  display?: string;
  title?: string;
  subTitle?: string;
  autoplay?: boolean;
  videoId?: string | number;
  loop?: boolean;
  controls?: boolean;
  isPreview?: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  padding: 0,
  width: '100%',
  type: 'website',
  height: '100%',
  placement: 'center',
  display: 'default',
  border: 'none',
  title: '',
  subTitle: '',
  autoplay: true,
  isPreview: true,
  loop: false,
  controls: true,
  videoId: '',
});

enum YoutubeState {
  // UNSTARTED = -1,
  // ENDED = 0,
  PLAYING = 1,
  // PAUSED = 2,
  // BUFFERING = 3,
  // CUED = 5
}

const { getLocalizedText } = useReveal();
const { youtubeReady } = useYoutubeApi();
const { onPause, onResume, slideId, scope } = usePresence();
const iframeRef = ref();
const loading = ref(props.type === 'website');
const inScope = computed(() => {
  if (!props.isPreview || props.display === 'pannel') return true;
  return scope.value === slideId.value;
});
const fullScreenText = computed(() =>
  isFullscreen.value
    ? getLocalizedText('exit_full_screen')
    : getLocalizedText('full_screen'),
);

const youtubeRef = ref();
const vimeoRef = ref();
const youtubePlaying = ref(false);
const emit = defineEmits(['current-time']);

const onTimeupdate = useThrottleFn(({ seconds }) => {
  emit('current-time', { time: seconds, seconds: Math.floor(seconds) });
}, 500);
const handleYoutubeState = state => {
  youtubePlaying.value = state.data === YoutubeState.PLAYING;
};

if (props.type === 'youtube') {
  useInterval(10, {
    callback: () => {
      if (youtubePlaying.value) {
        let seconds = youtubeRef.value.getCurrentTime();
        onTimeupdate({ seconds });
      }
    },
  });
}

watch(
  () => props.src,
  (newSrc, oldSrc) => {
    if (newSrc !== oldSrc && props.type === 'website') {
      loading.value = true;
    }
  },
);

const containerClasses = computed<string>(() =>
  classNames(borderClasses[props.border], placementClasses[props.placement]),
);

onPause(() => stopVideo());
onResume(() => playVideo());

const playVideo = async () => {
  try {
    if (props.type === 'vimeo' && inScope.value) {
      vimeoRef.value.play();
    }

    if (props.type === 'youtube' && inScope.value) {
      youtubeRef.value.playVideo();
    }
  } catch (err) {
    console.log('error', err);
  }
};

const stopVideo = async () => {
  if (props.type === 'vimeo') {
    vimeoRef.value.pause();
  }

  if (props.type === 'youtube') {
    youtubeRef.value.pauseVideo();
  }
};

const adaptVimeo = () => {
  let iframe = vimeoRef.value.querySelector('iframe');
  iframe.setAttribute('width', '100%');
  iframe.setAttribute('height', '100%');
};

let vimeoPlayer: Player | null = null; // using a ref results in error from vimeo library

onMounted(async () => {
  const options = {
    url: props.src,
    width: props.width,
    loop: props.loop,
  };
  await nextTick();
  if (props.type === 'vimeo') {
    vimeoPlayer = new VimeoPlayer(vimeoRef.value, options);
    vimeoPlayer.on('play', () => {
      onResume();
    });

    vimeoPlayer.on('pause', () => {
      onPause();
    });

    vimeoPlayer.on('timeupdate', data => {
      onTimeupdate({ seconds: data.seconds });
    });
    vimeoPlayer.on('loaded', () => {
      adaptVimeo();
    });
  }
});
</script>

<style>
.full-screen-shadow {
  box-shadow:
    0px 2px 10px 1px rgba(12, 74, 110, 0.15),
    0px 1px 3px 0px rgba(12, 74, 110, 0.05);
}
</style>
