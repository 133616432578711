<template>
  <FormControl :title="title" class="w-full">
    <div
      v-for="({}, index) in models"
      :key="index"
      class="flex flex-col rounded-lg w-full divide-y">
      <EditableText
        v-model="models[index].text"
        placeholder="הנושא שלי"
        pretext="נושא: "
        class="w-full p-3"/>
      <AdvancedTable
        v-model="models[index].content"
        :headers="headers"
        :items="items[index]?.content"
        :formatting="formatting"/>
    </div>
  </FormControl>
</template>

<script lang="ts" setup>
import { watch } from 'vue';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import EditableText from '@/components/EditableText/EditableText.vue';
import { useVModel } from '@vueuse/core';
import AdvancedTable from '@/components/Table/AdvancedTable.vue';

interface Item {
  id: string;
  text: string;
  content?: Array<{
    id: string;
    [key: string]: any;
  }>;
}

const props = defineProps({
  modelValue: {
    type: Array as () => Array<Item>,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  headers: {
    type: Array as any,
    required: true,
  },
  items: {
    type: Array as () => Array<Item>,
    default: () => [],
  },
  formatting: {
    type: Object,
    default: () => ({
      minRows: 1,
      maxRows: 15,
      textarea: {
        min: 1,
        max: 2,
      },
    }),
  },
});
const emit = defineEmits(['update:modelValue']);

const models = useVModel(props, 'modelValue', emit);

watch(
  () => [props.modelValue, props.items],
  () => {
    props.items.forEach((option, index) => {
      if (models.value[index] && models.value[index].id === option.id) {
        return;
      }

      models.value[index] = {
        id: option.id,
        text: option.text,
        content: [],
      };
    });
  },
  { immediate: true },
);
</script>
