<template>
  <div
    class="min-h-92 relative px-5 sort-content"
    :class="containerClass"
    :dir="computedDirection">
    <div
      v-for="(element, index) in model"
      :key="element.id"
      :dir="isRtl && layout !== 'two-columns' ? 'rtl' : 'ltr'"
      class="absolute z-10 pt-1"
      :style="numberStyle(index)">
      {{ index + 1 }}.
    </div>
    <div
      v-if="showAddOptionRtlHorizontal"
      class="flex items-center relative w-5 h-20">
      <div v-if="canAddMoreOptions" class="flex items-center justify-center">
        <BaseTooltip popper-class="z-[9999]" placement="top">
          <template #trigger>
            <div
              class="w-4 h-4 rounded-full bg-primary group/add hover:h-6 hover:w-6 flex items-center justify-center cursor-pointer"
              @click="emit('add-option')">
              <Plus
                class="stroke-white w-5 invisible group-hover/add:visible h-5"/>
            </div>
          </template>
          <template #content>
            <span>{{ t('editor.add_answer') }}</span>
          </template>
        </BaseTooltip>
      </div>
      <div
        class="absolute right-0 w-15 flex justify-start text-secondary-500 bottom-0 -left-3">
        {{ `(${model.length}/ ${maxOptions})` }}
      </div>
    </div>
    <VueDraggable
      :class="containerClassChild"
      :list="model"
      :group="{ name: `choices-${blockId}`, pull: 'clone', put: false }"
      :style="twoColumnsStyle"
      item-key="name"
      :animation="200"
      :force-fallback="!isPreview"
      :scroll-sensitivity="isPreview ? 200 : 0"
      @change="handleDrag($event)"
      @start="onDragStart"
      @end="onDragEnd">
      <template #item="{ element, index }">
        <div
          class="flex gap-2 group/edit h-22 items-center"
          :dir="isRtl ? 'rtl' : 'ltr'"
          :class="{ dragging: draggedIndex === index }">
          <div class="flex flex-col">
            <div
              class="flex h-22 mb-2 gap-2 items-center rounded-md bg-[#F7F9FD] shadow-dropdown-light border-4 relative"
              :style="{ width: '8.5rem' }"
              :class="{
                'opacity-0': draggedIndex === index && (!isPreview || isRtl),
                'border-white': draggedIndex !== index,
              }">
              <OverlayPanel
                v-if="!isPreview"
                :ref="el => (panelsRefs[index] = el)">
                <PollImageInput
                  :id="element.id || ''"
                  :is-first="index === 0"
                  :is-last="index === model.length - 1"
                  :placement="'end'"
                  :is-modal="isModal"
                  @replace-image="setImage"/>
              </OverlayPanel>
              <div
                class="flex items-center w-full cursor-grab active:cursor-grabbing h-full relative"
                @click="togglePanel(index, $event)">
                <div
                  class="absolute start-0 flex items-center bg-white group-hover/edit:bg-primary-200 py-2 px-1 rounded-e">
                  <Ellipsis
                    :class="{
                      'stroke-white': draggedIndex === index,
                    }"/>
                </div>
                <div v-if="!isPreview" class="h-full w-full justify-center">
                  <div
                    v-if="!element.image"
                    class="flex flex-col items-center justify-center gap-1 w-full h-full ps-2">
                    <FolderUp class="stroke-secondary-900 h-4" />
                    <span class="text-xs text-secondary-900">
                      {{ t('blocksSettings.pairing.default_image_text') }}
                    </span>
                    <span
                      class="text-[10px] leading-4 text-secondary-500"
                      dir="rtl">
                      {{
                        t('blocksSettings.pairing.default_image_secondary_text')
                      }}
                    </span>
                  </div>
                  <div
                    v-else
                    class="bg-[#D7DFEA] h-full w-full flex justify-center rounded">
                    <img
                      :src="element.image"
                      :alt="index"
                      class="h-full w-full rounded"
                      :class="{
                        'object-cover': !isVerticalImage[index],
                        'object-contain': isVerticalImage[index],
                      }"
                      @load="checkImageDimensions($event, index)"/>
                  </div>
                </div>
                <div v-else class="bg-[#D7DFEA] h-full w-full rounded">
                  <img
                    v-if="element.image"
                    :src="element.image"
                    :alt="index"
                    class="h-full w-full rounded"
                    :class="{
                      'object-cover': !isVerticalImage[index],
                      'object-contain': isVerticalImage[index],
                    }"
                    @load="checkImageDimensions($event, index)"/>
                </div>
              </div>
            </div>
            <div
              v-if="
                !isPreview &&
                layout === 'two-columns' &&
                index === model.length - 1
              "
              class="flex items-center w-full h-3 relative">
              <div
                v-if="canAddMoreOptions"
                class="flex items-center w-full justify-center">
                <BaseTooltip popper-class="z-[9999]" placement="top">
                  <template #trigger>
                    <div
                      class="w-4 h-4 rounded-full bg-primary group/add hover:h-6 hover:w-6 flex items-center justify-center cursor-pointer mt-2"
                      @click="emit('add-option')">
                      <Plus
                        class="stroke-white w-5 invisible group-hover/add:visible h-5"/>
                    </div>
                  </template>
                  <template #content>
                    <span>{{ t('editor.add_answer') }}</span>
                  </template>
                </BaseTooltip>
              </div>
              <div
                class="absolute top-5 right-1 flex justify-end text-secondary-500"
                dir="ltr">
                {{ `(${model.length}/ ${maxOptions})` }}
              </div>
            </div>
          </div>
          <div
            :class="{ invisible: model.length <= 2 || isPreview }"
            class="flex items-center justify-center cursor-pointer w-5 h-5 rounded-full p-1 bg-secondary-200 hover:bg-secondary-300 opacity-0 group-hover/edit:opacity-100 transition-opacity duration-200"
            @click="emit('delete-option', index)">
            <X class="w-4 h-4 stroke-[1.2px]" />
          </div>
        </div>
      </template>
    </VueDraggable>
    <div
      v-if="showAddOptionNonRtlVertical"
      class="flex items-center relative"
      :style="{
        width: layout === 'vertical' ? '8.5rem' : '5.5rem',
        height: layout === 'vertical' ? '2.5rem' : '5rem',
      }">
      <div
        v-if="canAddMoreOptions"
        class="flex items-center justify-center"
        :class="{
          'w-full': layout === 'vertical',
        }">
        <BaseTooltip popper-class="z-[9999]" placement="top">
          <template #trigger>
            <div
              class="w-4 h-4 rounded-full bg-primary group/add hover:h-6 hover:w-6 flex items-center justify-center cursor-pointer"
              @click="emit('add-option')">
              <Plus
                class="stroke-white w-5 invisible group-hover/add:visible h-5"/>
            </div>
          </template>
          <template #content>
            <span>{{ t('editor.add_answer') }}</span>
          </template>
        </BaseTooltip>
      </div>
      <div
        class="absolute w-15 flex justify-end text-secondary-500"
        :style="{
          left: layout === 'vertical' ? '0' : 'auto',
          bottom: layout === 'horizontal' ? '0' : 'auto',
        }">
        {{ `(${model.length}/ ${maxOptions})` }}
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import VueDraggable from 'vuedraggable';
import Ellipsis from '@/assets/ellipsis.svg?component';
import { Plus, X, FolderUp } from 'lucide-vue-next';
import OverlayPanel from 'primevue/overlaypanel';
import PollImageInput from '../poll/PollImageInput.vue';
import type { SortOption } from './sort';
import { BaseTooltip } from '@amit/foundation';

const props = defineProps({
  isPreview: {
    type: Boolean,
    default: true,
  },
  isModal: {
    type: Boolean,
    default: false,
  },
  layout: {
    type: String,
    default: 'vertical',
  },
  locale: {
    type: String,
    default: 'he',
  },
  modelValue: {
    type: Array<SortOption>,
    default: () => [],
  },
  blockId: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();
const draggedIndex = ref(null);
const model = computed(() => [...props.modelValue]);
const maxOptions = computed(() => (props.layout === 'horizontal' ? 5 : 10));
const isRtl = computed(() => props.locale === 'he');
const panelsRefs = ref([]);
const canAddMoreOptions = computed(() => model.value.length < maxOptions.value);
const showAddOptionRtlHorizontal = computed(
  () => !props.isPreview && props.layout === 'horizontal' && isRtl.value,
);

const isVerticalImage = ref([]);

const checkImageDimensions = (event, index) => {
  const img = event.target;
  if (img.naturalHeight > img.naturalWidth) {
    isVerticalImage.value[index] = true;
  } else {
    isVerticalImage.value[index] = false;
  }
};

const showAddOptionNonRtlVertical = computed(
  () =>
    !props.isPreview &&
    ((!isRtl.value && props.layout !== 'two-columns') ||
      (isRtl.value && props.layout === 'vertical')),
);

const computedDirection = computed(() => {
  if (props.layout === 'vertical') {
    return isRtl.value ? 'rtl' : 'ltr';
  }
  return 'ltr';
});

const emit = defineEmits([
  'update:modelValue',
  'add-option',
  'delete-option',
  'update-options',
]);

const twoColumnsStyle = computed(() => {
  if (props.layout === 'two-columns') {
    return { height: '530px', width: '380px' };
  }
  return {};
});

const containerClass = computed(() => {
  if (props.layout === 'horizontal') {
    return `flex flex-row gap-2 ${isRtl.value ? 'justify-end' : 'justify-start'}`;
  } else if (props.layout === 'two-columns') {
    return `flex flex-col ${isRtl.value ? 'items-end' : 'justify-start'}`;
  } else {
    return 'flex flex-col gap-4 w-full';
  }
});

const containerClassChild = computed(() => {
  if (props.layout === 'two-columns') {
    const onRow = model.value.length <= 5;
    return `flex flex-col flex-wrap gap-4 ${onRow && isRtl.value ? 'mr-52' : 'mr-0'}`;
  }
  if (props.layout === 'horizontal') {
    return `flex flex-row gap-2 ${isRtl.value ? 'justify-end' : 'justify-start'}`;
  } else {
    return 'flex flex-col gap-4 w-full';
  }
});

const handleDrag = event => {
  emit('update:modelValue', event);
  emit('update-options', model.value);
};

const onDragStart = evt => {
  draggedIndex.value = evt.oldIndex;
};

const onDragEnd = () => {
  draggedIndex.value = null;
};

const numberStyle = index => {
  if (props.layout === 'horizontal') {
    const rightPosition = index === 0 ? '0rem' : `${index * 10.7}rem`;
    return {
      top: '0',
      [isRtl.value ? 'right' : 'left']: rightPosition,
    };
  } else if (props.layout === 'two-columns') {
    const rowIndex = index < 5 ? index : index - 5;
    const rightPosition = isRtl.value
      ? index < 5
        ? '23rem'
        : '10.5rem'
      : index < 5
        ? '0rem'
        : '12rem';
    return {
      top: `${rowIndex * 6.6}rem`,
      [isRtl.value ? 'right' : 'left']: rightPosition,
    };
  } else {
    return {
      top: `${index * 6.6}rem`,
      insetInlineStart: '0rem',
    };
  }
};

const setImage = (id, url) => {
  const currentOption = model.value.find(option => option.id === id);
  if (currentOption) {
    currentOption.image = url;
  }
};

const togglePanel = (index, event) => {
  panelsRefs.value[index].toggle(event);
};
</script>
