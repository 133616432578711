<template>
  <VueDraggable
    v-model="model"
    :group="groupName"
    item-key="id"
    :sort="false"
    class="grow w-full flex flex-wrap content-start gap-2 cursor-pointer h-full"
    @remove="event => model.slice(event.oldIndex, 1)"
    @drag="onDragMove"
    @drop="emit('drop')">
    <template #item="{ index }">
      <TagInput
        class="tag-item"
        :title="getTitle(model[index])"
        :color="getColor(model[index])"
        :removable="false"
        @dragstart="onStart"
        @dragend="onEnd"/>
    </template>
  </VueDraggable>
</template>

<script setup lang="ts">
import VueDraggable from 'vuedraggable';
import TagInput from '@/components/TagsInput/TagInput.vue';
import { useVModel } from '@vueuse/core';
import type { ITaskModel, ICalendarEvent } from '@/types/interfaces';
import { scrollParent } from '@/utils.ts';

interface IProps {
  modelValue: ITaskModel[] | ICalendarEvent[] | string[];
  groupName: string;
}
const emit = defineEmits(['hidden', 'drop']);

const props = withDefaults(defineProps<IProps>(), {
  modelValue: () => [],
  groupName: '',
});

const model = useVModel(props, 'modelValue');

const onStart = event => {
  let tagItem: Element = event.target;
  if (tagItem) {
    tagItem.classList.add('min-h-8');
    tagItem.classList.remove('border');
    tagItem.classList.remove('tag-hover-state');
    event.dataTransfer.setDragImage(tagItem, 0, 0);
  }
};

const onEnd = event => {
  let tagItem: Element = event.target;
  if (tagItem) {
    tagItem.classList.remove('min-h-8');
    tagItem.classList.add('border');
    tagItem.classList.add('hover:tag-hover-state');
    event.dataTransfer.setDragImage(tagItem, 0, 0);
  }
};

const getTitle = element => {
  if (typeof element === 'object') {
    return element.title;
  }
  return element;
};
const getColor = element => {
  if (typeof element === 'object') {
    return element.label;
  }
  return 'default';
};

const onDragMove = event => {
  scrollParent(document.querySelector('section.present > .r-stack'), event);
};
</script>
