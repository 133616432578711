<template>
  <FormControl :title="title" :sub-title="subTitle">
    <component
      :is="!review ? WordBank : 'div'"
      :default-size="80"
      :side-title="getLocalizedText('word_bank')"
      :class="{ 'bg-white p-5': review }">
      <template #default>
        <div
          ref="bankTextElement"
          class="w-full grid gap-4 grid-cols-[repeat(auto-fit,minmax(150px,1fr))]">
          <div v-for="(match, index) in matches" :key="index" class=" ">
            <TagsInput
              ref="tagsInputRefs"
              v-model="model[match.id]"
              :group="`match_answers-${id}`"
              :height="'300px'"
              :title="match.title"
              :is-draggable="true"
              @drop="clearAllTagsInput"/>
          </div>
        </div>
      </template>
      <template #side>
        <BankBanner
          v-if="showBank"
          v-model="optionsRef"
          :group-name="`match_answers-${id}`"
          @hidden="closeBank"
          @drop="clearAllTagsInput">
          <template #item="{}"></template>
        </BankBanner>
      </template>
    </component>
  </FormControl>
</template>

<script setup lang="ts">
import BankBanner from '@/components/BankBanner/BankBanner.vue';
import type { PropType } from 'vue';
import { onMounted, ref, watch, watchEffect } from 'vue';
import type { ITag, ITaskModel } from '@/types/interfaces';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import { useElementVisibility, useVModel } from '@vueuse/core';
import TagsInput from '@/components/TagsInput/TagsInput.vue';

import { shuffle as shuffleArray } from 'lodash';
import WordBank from '@/components/WordBank/WordBank.vue';
import { useReveal } from '@/apps/slideshow/useReveal';

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  matches: {
    type: Array as PropType<ITag[]>,
    required: true,
    default: () => [],
  },
  options: {
    type: Array as PropType<ITaskModel[]>,
    required: true,
  },
  modelValue: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  locale: {
    type: String,
    default: 'he',
  },
  shuffle: {
    type: Boolean,
    default: false,
  },
  review: {
    type: Boolean,
    default: false,
  },
});

const { getLocalizedText } = useReveal();

const optionsRef: any = ref();
const showBank = ref(false);
const emit = defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue', emit);
const bankTextElement = ref();
const targetIsVisible = useElementVisibility(bankTextElement);

const closeBank = () => {
  showBank.value = false;
};

watchEffect(() => {
  showBank.value = targetIsVisible.value;
});

onMounted(() => {
  props.matches.forEach(match => {
    if (!model.value[match.id]) {
      model.value[match.id] = [];
    }
  });
});

watch(
  () => props.modelValue,
  () => {
    const modelValues = Object.values(model.value).flat();
    const options = props.options.filter(option => {
      return !modelValues.some(
        (modelValue: any) => modelValue.id === option.id,
      );
    });
    optionsRef.value = props.shuffle ? shuffleArray(options) : options;
  },
  { immediate: true },
);

const tagsInputRefs = ref([]);

// Clear the isActive state for all TagsInput components
const clearAllTagsInput = () => {
  tagsInputRefs.value.forEach(tagInput => {
    //@ts-ignore
    tagInput?.setIsActive(false);
  });
};
</script>
