<template>
  <div
    class="relative gap-2 flex flex-col items-center rounded-2xl overflow-hidden bg-black"
    :class="{ 'limited-controls': !showAddRule && !showControls }">
    <video
      v-if="type === 'custom'"
      ref="videoRef"
      controls
      class="bg-black h-full aspect-video"
      :style="{ width: width }"
      controlsList="nodownload noplaybackrate"
      disablePictureInPicture
      @canplay="onCanPlay"
      @loadedmetadata="emit('loadedmetadata')"
      @timeupdate="onTimeUpdate"/>
    <div
      v-if="type === 'vimeo'"
      :id="`vimeo-player-${vimeoId}`"
      ref="vimeoRef"
      class="aspect-video w-full max-w-4xl vimeo-player"></div>

    <YoutubePlayer
      v-if="type === 'youtube' && youtubeReady"
      ref="youtubeRef"
      class="aspect-video w-full max-w-4xl youtube-player"
      :src="src"
      :width="width"
      :vars="playerVars"
      height="100%"
      @ready="onPlayerReady"
      @state-change="handleYoutubeState"/>
    <div
      v-if="processing && type === 'custom'"
      class="absolute h-full w-full flex items-center justify-center bg-[#F2F4F8E5]">
      <span
        class="font-simplerRegular font-normal w-20/100 text-center text-sm"
        dir="rtl">
        כמה דקות והסרטון יהיה זמין לצפייה, בינתיים ניתן להמשיך לעבוד!
      </span>
    </div>
    <div v-if="showAddRule" class="absolute bottom-[80px] left-4 z-50">
      <BaseTooltip popper-class="z-[9999999]" placement="top">
        <template #trigger>
          <button
            class="h-8 w-8 flex items-center justify-center z-50 bg-primary-500 rounded-md p-2"
            @click="openQuestionsModal">
            <PenLine v-if="isRulesBlocks.length" class="h-10 stroke-white" />
            <Plus v-else class="h-8 stroke-white" />
          </button>
        </template>
        <template #content>
          <span v-if="!isRulesBlocks.length">
            {{ t('blocksSettings.custom_video.add_exercise_modal') }}
          </span>
          <span v-else>
            {{ t('blocksSettings.custom_video.edit_exercise_modal') }}
          </span>
        </template>
      </BaseTooltip>
    </div>
    <loading-overlay
      :active="loading"
      :can-cancel="false"
      :is-full-page="false"
      :opacity="0.8"
      :z-index="9999999"
      color="#fff"
      background-color="#333"/>
  </div>
</template>
<script setup lang="ts">
import {
  computed,
  inject,
  onMounted,
  watch,
  ref,
  nextTick,
  onUnmounted,
} from 'vue';
import { useMediaControls, useEventBus } from '@vueuse/core';
import LoadingOverlay from 'vue-loading-overlay';
import YoutubePlayer from 'vue3-youtube';
import { useYoutubeApi } from '@/composables/useYoutubeApi';
import VimeoPlayer, { Player } from '@vimeo/player';
import { nanoid } from 'nanoid';
import { Plus, PenLine } from 'lucide-vue-next';
import { useI18n } from 'vue-i18n';
import { BaseTooltip } from '@amit/foundation';

const toast = inject('moshaToast');

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  width: {
    type: String,
    default: '100%',
  },
  type: {
    type: String,
    default: 'custom',
  },
  block: {
    type: Object,
    required: true,
  },
  isRulesBlocks: {
    type: Array,
    default: () => [],
    required: true,
  },
  showAddRule: {
    type: Boolean,
    default: true,
  },
  showControls: {
    type: Boolean,
    default: false,
  },
});
const videoRef = ref<HTMLVideoElement | null>(null);
const vimeoRef = ref();
const loading = ref(true);
const youtubeRef = ref();
const processing = ref(false);
const vimeoId = ref(nanoid());
const youtubePlaying = ref(false);

const { t } = useI18n();
const emit = defineEmits([
  'update-end-time',
  'timeupdate',
  'loadedmetadata',
  'is-youtube-playing',
]);
const { youtubeReady } = useYoutubeApi();
const startTime = ref(props.block?.content?.startTime);

const initialSeekDone = ref(false);

const videoMethods = {
  async seekTo(seconds: number) {
    try {
      if (props.type === 'youtube' && youtubeRef.value?.player) {
        if (!youtubeRef.value.player.seekTo) {
          await new Promise(resolve => setTimeout(resolve, 500));
        }
        startTime.value = seconds;
        if (youtubeRef.value.player.seekTo) {
          await youtubeRef.value.player.seekTo(seconds, true);
          emit('timeupdate', { currentTime: seconds });
        }
      } else if (props.type === 'vimeo' && vimeoPlayer) {
        await vimeoPlayer.setCurrentTime(seconds);
      } else if (videoRef.value) {
        videoRef.value.currentTime = seconds;
      }
    } catch (error) {
      console.error(`Error seeking ${props.type} video:`, error);
    }
  },

  getCurrentTime() {
    if (props.type === 'youtube' && youtubeRef.value?.player) {
      return youtubeRef.value.player.getCurrentTime();
    } else if (props.type === 'vimeo' && vimeoPlayer) {
      return vimeoPlayer.getCurrentTime();
    } else if (videoRef.value) {
      return videoRef.value.currentTime;
    }
    return 0;
  },

  async getDuration() {
    if (props.type === 'youtube' && youtubeRef.value?.player) {
      return props.block?.content?.endTime;
    } else if (props.type === 'vimeo' && vimeoPlayer) {
      const duration = await vimeoPlayer.getDuration();
      return duration;
    } else if (videoRef.value) {
      return videoRef.value.duration;
    }
    return 0;
  },
};

defineExpose(videoMethods);

const openQuestionsModal = () => {
  const blockSpecificBus = useEventBus(
    `questions-modal-${props.block?.page}-${props.block?.id}`,
  );
  blockSpecificBus.emit({
    type: 'open-questions-modal',
    pageId: props.block?.page,
    blockId: props.block?.id,
    block: props.block,
  });
};

const playerVars = computed(() => ({
  start: startTime.value,
  end: props.block?.content?.endTime,
}));

const onTimeUpdate = async event => {
  if (props.type === 'custom') {
    const currentTime = event.target.currentTime;
    emit('timeupdate', { currentTime });
  }
};

const lowQualitySrc = computed(() => {
  const match = '/upload/';
  const quality = 'q_auto:low';
  return props.src.includes(match)
    ? props.src.replace(match, `${match}${quality}/`)
    : props.src;
});

useMediaControls(videoRef, {
  src: lowQualitySrc.value,
});
const timeUpdateInterval = ref();

const handleYoutubeState = async state => {
  console.log(state, 'state');
  youtubePlaying.value = state.data === 1;
  emit('is-youtube-playing', state.data === 1);

  if (!initialSeekDone.value && startTime.value && !props.showAddRule) {
    if (state.data === 1) {
      initialSeekDone.value = true;
      await youtubeRef.value.player.seekTo(startTime.value, true);
      await youtubeRef.value.player.pauseVideo();
    }
  }

  // Clear existing interval if any
  if (timeUpdateInterval.value) {
    clearInterval(timeUpdateInterval.value);
    timeUpdateInterval.value = null;
  }

  if (state.data === 1) {
    timeUpdateInterval.value = setInterval(() => {
      const currentTime = youtubeRef.value.player.getCurrentTime();
      emit('timeupdate', { currentTime });
    }, 100);
  }
};

onUnmounted(() => {
  if (timeUpdateInterval.value) {
    clearInterval(timeUpdateInterval.value);
  }
});

const onCanPlay = () => {
  if (typeof toast === 'function' && processing.value) {
    toast('הסרטון מוכן לצפייה!', {
      position: 'top-center',
      type: 'success',
      hideProgressBar: true,
      transition: 'zoom',
    });
  }
  loading.value = false;
  processing.value = false;
};

const onPlayerReady = async () => {
  loading.value = false;
  emit('update-end-time', youtubeRef.value.getDuration());
  emit('loadedmetadata');
};

let vimeoPlayer: Player | null = null;

const adaptVimeo = () => {
  let iframe = vimeoRef.value.querySelector('iframe');
  iframe.setAttribute('width', '100%');
  iframe.setAttribute('height', '100%');
};

onMounted(async () => {
  setTimeout(() => {
    if (loading.value) {
      loading.value = false;
      processing.value = true;
    }
  }, 5000);

  const options = {
    url: props.src,
  };
  if (props.type === 'vimeo') {
    await nextTick();

    vimeoPlayer = new VimeoPlayer(vimeoRef.value, options);
    vimeoPlayer.on('loaded', () => {
      adaptVimeo();
      emit('loadedmetadata');
    });
    vimeoPlayer.on('timeupdate', ({ seconds }) => {
      emit('timeupdate', { currentTime: seconds });
    });
  }
});

watch(
  () => props.src,
  newSrc => {
    if (videoRef.value) {
      videoRef.value.src = newSrc;
      videoRef.value.load();
    }
  },
);
</script>

<style scoped>
.limited-controls :deep(video::-webkit-media-controls-volume-slider),
.limited-controls :deep(video::-webkit-media-controls-mute-button),
.limited-controls :deep(video::-webkit-media-controls-fullscreen-button),
.limited-controls :deep(video::-webkit-media-controls-rewind-button),
.limited-controls
  :deep(video::-webkit-media-controls-toggle-closed-captions-button) {
  display: none;
}

:deep(.vimeo-player),
:deep(.youtube-player) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
</style>
