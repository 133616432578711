<template>
  <div v-for="(option, index) in options" :key="index">
    <div class="flex w-full gap-2 items-center group/edit">
      <div
        class="flex items-center gap-2 w-full cursor-pointer rounded-xl p-3 border border-secondary-300"
        :class="getClass(option.id)"
        @click="toggleAnswer(option.id)">
        <component
          :is="
            type === 'checkbox'
              ? CheckboxIcon
              : type === 'radio'
                ? RadioIcon
                : SwitchIcon
          "
          :model-value="answer === option.id"
          :model-name="option.title"
          :is-disabled="disabled"/>
        <div v-if="!isPreview" class="ms-2 w-full">
          <HeadLine
            v-model="option.title"
            :closable="false"
            :placeholder="
              t('blocksSettings.choice.default_title_field_placeholder')
            "
            class="w-full"/>
        </div>
        <div v-else>
          <span>{{ option.title }}</span>
        </div>
      </div>
      <div
        v-if="options.length > 2 && !isPreview"
        class="flex items-center justify-center cursor-pointer rounded-full p-1 bg-secondary-200 hover:bg-secondary-300 opacity-0 group-hover/edit:opacity-100 transition-opacity duration-200"
        @click="$emit('delete-option', index)">
        <X class="w-4 h-4 stroke-[1.2px]" />
      </div>
    </div>
  </div>
  <div
    v-if="options.length < 10 && !isPreview"
    class="flex items-center h-10 w-full justify-center">
    <BaseTooltip popper-class="z-[9999]" placement="top">
      <template #trigger>
        <div
          class="w-4 h-4 rounded-full bg-primary group/add hover:h-6 hover:w-6 flex items-center justify-center cursor-pointer"
          @click="$emit('add-option')">
          <Plus
            class="stroke-white w-5 invisible group-hover/add:visible h-5"/>
        </div>
      </template>
      <template #content>
        <span>{{ t('editor.add_answer') }}</span>
      </template>
    </BaseTooltip>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import { Plus, X } from 'lucide-vue-next';
import { useI18n } from 'vue-i18n';
import { HeadLine } from '@amit/components';
import CheckboxIcon from '@/components/Elements/CheckboxIcon.vue';
import RadioIcon from '@/components/Elements/RadioIcon.vue';
import SwitchIcon from '@/components/Elements/SwitchIcon.vue';
import { BaseTooltip } from '@amit/foundation';

const { t } = useI18n();

interface Option {
  title: string;
  id: string;
}

interface Choice {
  is_correct: boolean;
  id: string;
}

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'radio',
  },
  options: {
    type: Array as PropType<Option[]>,
    default: () => [],
  },
  choices: {
    type: Array as PropType<Choice[]>,
    default: () => [],
  },
  needReview: {
    type: Boolean,
    default: true,
  },
  isPreview: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['toggle-option', 'delete-option', 'add-option']);

const answer = computed(() => props.modelValue);
const toggleAnswer = (id: string) => {
  if (!props.disabled) {
    emit('toggle-option', id);
  }
};

const getClass = optionId => {
  if (props.needReview) {
    return 'bg-secondary-50';
  }
  const isCorrect = props.choices.find(
    choice => choice.id === optionId,
  )?.is_correct;
  return isCorrect ? 'bg-green-50' : 'bg-red-50';
};
</script>
