<template>
  <div class="overflow-hidden w-full">
    <div class="grid" :style="gridStyle">
      <div v-for="(header, index) in headers" :key="'header-' + index">
        <slot name="header">
          <p class="px-4 font-simplerBold text-center">
            {{ header.title }}
          </p>
        </slot>
      </div>
      <template v-for="(side, rowIndex) in items" :key="'row-' + rowIndex">
        <p v-if="side?.title?.title" class="py-4 font-simplerBold text-start">
          {{ side?.title?.title }}
        </p>

        <div
          v-for="(header, colIndex) in headers.slice(hasSideHeader)"
          :key="'cell-' + rowIndex + '-' + colIndex"
          class="p-2">
          <slot
            :name="`cell-${rowIndex}-${colIndex}`"
            v-bind="{ rowIndex, colIndex, header, item: side[header.key] }">
            <slot
              :name="`cell-${rowIndex}`"
              v-bind="{ rowIndex, colIndex, header, item: side[header.key] }">
              <slot
                name="cell"
                v-bind="{ rowIndex, colIndex, header, item: side[header.key] }"/>
            </slot>
          </slot>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  title: {
    type: String as any,
    default: '',
  },
  headers: {
    type: Array as any,
    default: () => [],
  },
  items: {
    type: Array as any,
    default: () => [],
  },
  headerWidth: {
    type: String,
    default: '70px',
  },
});

const hasSideHeader = computed(() =>
  props.items.some(item => item?.title?.title),
);

const gridStyle = computed(() => ({
  gridTemplateColumns: hasSideHeader.value
    ? `${props.headerWidth} repeat(${props.headers.length - 1}, 1fr)`
    : `repeat(${props.headers.length}, 1fr)`,
}));
</script>
