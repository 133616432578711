<template>
  <FormControl :title="title" :sub-title="subTitle">
    <span class="font-simplerBold">{{ description }}</span>

    <component
      :is="!review ? WordBank : 'div'"
      direction="horizontal"
      :side-title="getLocalizedText('word_bank')"
      :class="{ 'bg-white p-5': review }">
      <template #default>
        <BuildTable :headers="headers" :items="items">
          <template #cell="{ rowIndex, header, item }">
            <TagsInput
              :ref="
                el => {
                  if (el) tagsInputRefs[rowIndex] = el;
                }
              "
              v-model="model[rowIndex][header.key]"
              :height="height"
              :title="item?.title"
              :options="item?.content"
              :group="`draggable_table_answers-${id}`"
              :disabled="!item?.droppable"
              :highlighted="!review && highlighted && item?.droppable"
              :is-draggable="true"
              @drop="clearAllTagsInput"/>
          </template>
        </BuildTable>
      </template>
      <template #side>
        <BankBanner
          v-model="optionsRef"
          :group-name="`draggable_table_answers-${id}`"
          @drop="clearAllTagsInput"/>
      </template>
    </component>
  </FormControl>
</template>

<script setup lang="ts">
import BankBanner from '@/components/BankBanner/BankBanner.vue';
import {
  nextTick,
  ref,
  watch,
  watchEffect,
  ComponentPublicInstance,
} from 'vue';
import { useVModel } from '@vueuse/core';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import type { ITagTable } from '@/types/interfaces';
import type { PropType } from 'vue';
import WordBank from '@/components/WordBank/WordBank.vue';
import TagsInput from '@/components/TagsInput/TagsInput.vue';
import { useReveal } from '@/apps/slideshow/useReveal';
import BuildTable from '@/components/BuildTable/BuildTable.vue';
const emit = defineEmits(['update:modelValue', 'add']);
const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  headers: {
    type: Array as any,
    required: true,
  },
  headerWidth: {
    type: Number,
    default: 70,
  },
  items: {
    type: Array as any,
    required: true,
  },
  options: {
    type: Array as any,
    default: () => [],
  },
  height: {
    type: String,
    default: '300px',
  },
  description: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Array as PropType<ITagTable[]>,
    default: () => [],
  },
  review: {
    type: Boolean,
    default: false,
  },
  highlighted: {
    type: Boolean,
    default: false,
  },
});

const optionsRef = ref();
const model = useVModel(props, 'modelValue', emit);

watchEffect(() => {
  const modelValueIds = props.modelValue.flatMap(row => {
    return props.headers.flatMap(header => {
      return row[header.key].flatMap(item => item.id);
    });
  });
  optionsRef.value = props.options.filter(
    option => !modelValueIds.includes(option.id),
  );
});

watch(
  () => props.modelValue,
  () => {
    props.items?.forEach((_, index) => {
      if (!model.value[index]) {
        model.value[index] = {
          id: index,
        };
        props.headers.forEach(header => {
          model.value[index][header.key] = [];
        });
      }
    });
  },
  { immediate: true },
);

const tagsInputRefs = ref<(Element | ComponentPublicInstance)[]>([]);

const { getLocalizedText } = useReveal();

const clearAllTagsInput = () => {
  nextTick(() => {
    tagsInputRefs.value?.forEach(tagInput => {
      //@ts-ignore
      tagInput?.setIsActive(false);
    });
  });
};
</script>

<style scoped>
.scrollbar-box::-webkit-scrollbar-thumb {
  @apply bg-secondary-300 rounded-lg;
}
</style>
