<template>
  <FormControl :title="title" :sub-title="subTitle">
    <SortChoice
      v-if="type === 'choice'"
      :model-value="newOptions"
      :block-id="id"
      :locale="locale"
      @update-options="handleUpdateOptions"/>
    <SortImage
      v-if="type === 'image'"
      :model-value="newOptions"
      :layout="layout"
      :locale="locale"
      :block-id="id"
      @update-options="handleUpdateOptions"/>
  </FormControl>
</template>
<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { PropType, ref, watch } from 'vue';
import type { SortOption } from './sort';
import { SortChoice, SortImage } from '@amit/blocks/';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import { shuffle as shuffleArray } from 'lodash';

const props = defineProps({
  modelValue: {
    type: Array as PropType<SortOption[]>,
    default: () => [],
  },
  id: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  options: {
    type: Array as PropType<SortOption[]>,
    default: () => [],
  },
  layout: {
    type: String,
    default: 'vertical',
  },
  locale: {
    type: String,
    default: 'he',
  },
  shuffle: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue', emit);
const newOptions = ref<SortOption[]>([]);
let lastShuffle;
watch(
  () => props.modelValue,
  () => {
    if (model.value.length > 0) {
      newOptions.value = model.value;
    } else if (props.shuffle) {
      newOptions.value = shuffleArray(props.options);
      lastShuffle = newOptions.value;
    } else {
      newOptions.value = lastShuffle ?? shuffleArray(props.options);
      lastShuffle = newOptions.value;
    }
  },
  { immediate: true },
);

const handleUpdateOptions = options => {
  model.value = options;
};
</script>
