<template>
  <FormControl :title="title" :sub-title="subTitle">
    <div class="flex items-center justify-center flex-col gap-4">
      <div
        v-if="!audioUrl && !recording"
        class="border-dotted border-b-8 w-1/2"></div>
      <AVMedia
        v-if="recording"
        type="frequ"
        line-color="#0FA9FF"
        :media="mediaObject"/>
      <span v-if="recording" class="text-4xl font-anomaliaLight">
        {{ minutes ?? '00' }}:{{ seconds ?? '00' }}
      </span>
      <div v-if="audioUrl && !recording">
        <audio ref="player" :src="audioUrl" />
        <canvas ref="canvas" />
        <div class="text-center m-5">
          <span class="text-4xl font-anomaliaLight">{{ time }}</span>
        </div>
      </div>
      <div class="flex">
        <BaseButton
          v-if="!playing"
          size="sm"
          color="blue-light"
          pill
          class="play-button mr-2 !p-3"
          :disabled="recording || !audioUrl"
          @click="onAudioPlay">
          <div class="flex items-center">
            <Play class="stroke-primary" />
            <span v-if="audioFile" class="text-primary">{{ playText }}</span>
          </div>
        </BaseButton>
        <BaseButton
          v-else
          size="sm"
          color="blue-light"
          pill
          class="mr-2 !p-3 w-12 h-12"
          :disabled="recording"
          @click="onAudioStop">
          <PauseIcon class="stroke-primary m-auto" />
        </BaseButton>
        <BaseButton
          v-if="!recording"
          variant="blue"
          size="sm"
          color="blue-light"
          pill
          class="mr-2 !p-3 !flex gap-2 !bg-primary"
          :disabled="permissionDenied"
          @click="onAudioRec">
          <div class="flex items-center">
            <Mic class="stroke-white" />
            <span v-if="audioFile" class="text-white">{{ micText }}</span>
          </div>
        </BaseButton>
        <BaseButton
          v-else
          size="sm"
          color="blue-light"
          pill
          class="!p-3 !bg-primary w-12 h-12"
          @click="onAudioRecStop">
          <StopIcon class="stroke-white m-auto" />
        </BaseButton>
      </div>
      <BaseAlert
        v-if="permissionDenied"
        class="h-10 max-w-50/100"
        type="danger"
        :text="permissionText"></BaseAlert>
    </div>
  </FormControl>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { BaseButton } from '@/components/index';
import { Mic, Play } from 'lucide-vue-next';
import { AVMedia, useAVWaveform } from 'vue-audio-visual';
import { useFileUpload } from '@/composables/useUpload';
import { useMediaControls, useVModel } from '@vueuse/core';
import { parseToMinutes } from '@/utils/helpers';
import { BaseAlert } from '@amit/foundation';
import { PauseIcon, StopIcon } from '@/index';
import FormControl from '@/components/utils/FormControl/FormControl.vue';

interface IProps {
  title?: string;
  subTitle?: string;
  modelValue?: { file: string };
  micText?: string;
  playText?: string;
  permissionText?: string;
}
const props = withDefaults(defineProps<IProps>(), {
  title: '',
  subTitle: '',
  permissionText: 'You need to get permission for using microphone',
  micText: 'Record again',
  playText: 'Hear yourself',
  modelValue: () => ({ file: '' }),
});

const emit = defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue', emit);

const audioRecorder = ref<any>({
  streamBeingCaptured: null,
  mediaRecorder: null,
});
const interval = ref<any>();
const seconds = ref<any>();
const minutes = ref<any>();
let sec = 0;
const maximumRecordTime = '05';
const canvas = ref<any>();
const player = ref<any>();
const mediaObject = ref<any>();
const audioFile = ref<any>();
const audioUrl = ref<any>(props.modelValue.file);
const recording = ref<any>();
const playing = ref<any>();
const audioChunks = ref<any>([]);
const { uploadFile } = useFileUpload();
const permissionDenied = ref<any>(false);
const { currentTime, ended } = useMediaControls(player, {
  src: audioUrl.value,
});

const time = computed(() => parseToMinutes(currentTime.value));

const init = () => {
  if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
    return;
  }

  navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then(stream => {
      permissionDenied.value = false;
      audioRecorder.value.streamBeingCaptured = stream;
      audioRecorder.value.mediaRecorder = new MediaRecorder(stream);
      mediaObject.value = new MediaRecorder(stream).stream;
      audioRecorder.value.mediaRecorder.addEventListener(
        'dataavailable',
        event => {
          audioChunks.value.push(event.data);
        },
      );
    })
    .catch(() => (permissionDenied.value = true));
};
const pad = val => {
  return val > 9 ? val : '0' + val;
};
const onAudioRec = () => {
  if (audioRecorder.value.mediaRecorder) {
    if (audioFile.value) {
      seconds.value = null;
      minutes.value = null;
      sec = 0;
      audioChunks.value = [];
    }
    interval.value = setInterval(() => {
      seconds.value = pad((++sec % 60).toString());
      minutes.value = pad(parseInt((sec / 60) as any, 10).toString());
    }, 1000);
    recording.value = true;
    audioRecorder.value.mediaRecorder.start();
  }
};
const onAudioRecStop = () => {
  recording.value = false;
  audioRecorder.value.mediaRecorder.addEventListener('stop', () => {
    audioFile.value = new Blob(audioChunks.value, { type: 'audio/mp3' });
    audioUrl.value = URL.createObjectURL(audioFile.value);
  });
  stopTimer();
  audioRecorder.value.mediaRecorder.stop();
};

watch(
  () => minutes.value,
  value => {
    if (value === maximumRecordTime) {
      onAudioRecStop();
    }
  },
);

const onAudioPlay = () => {
  playing.value = true;
  player.value.play();
};

const stopTimer = () => {
  clearInterval(interval.value);
};
const onAudioStop = () => {
  player.value.pause();
  playing.value = false;
  stopTimer();
};

const createWaveForm = audio => {
  useAVWaveform(player, canvas, {
    canvHeight: 40,
    canvWidth: 300,
    playtimeSliderColor: '#000',
    src: audio,
    playtime: false,
    playedLineWidth: 2,
    noplayedLineColor: '#B0BAC8',
    playedLineColor: '#0FA9FF',
  });
};

watch(
  () => audioUrl.value,
  value => {
    if (value) {
      createWaveForm(audioUrl.value);
    }
  },
  { immediate: true },
);

watch(ended, value => {
  if (value) {
    playing.value = false;
  }
});

watch(
  () => audioFile.value,
  async () => {
    if (!audioFile.value) return;
    const { url } = await uploadFile({ file: audioFile.value });
    model.value.file = url;
  },
);

onMounted(() => {
  init();
});

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue.file) {
      audioUrl.value = props.modelValue.file;
    } else {
      audioUrl.value = '';
      audioFile.value = null;
    }
  },
  { immediate: true },
);

onUnmounted(() => {
  if (audioRecorder.value.streamBeingCaptured) {
    audioRecorder.value.streamBeingCaptured
      .getTracks()
      .forEach(track => track.stop());
  }
});
</script>
