<template>
  <div
    :title="title"
    :class="{ [tagClasses]: true, 'hover:tag-hover-state': !disabled }"
    class="tag-input flex items-center gap-1 rounded-md">
    <slot name="prefix">
      <button v-if="removable" class="pl-1" @click="$emit('removeTag')">
        <Close :size="12" />
      </button>
    </slot>
    <slot name="text">
      {{ trucatedTitle }}
    </slot>
  </div>
</template>
<script setup lang="ts">
import { useTagInputClasses } from '@/components/TagsInput/composables/useTagInputClasses';
import type { TagType } from '@/components/TagsInput/types';
import { X as Close } from 'lucide-vue-next';
import { computed } from 'vue';

interface IProps {
  title?: string;
  removable?: boolean;
  disabled?: boolean;
  color?: TagType;
}

const props = withDefaults(defineProps<IProps>(), {
  title: '',
  removable: true,
  disabled: false,
  color: 'default',
});

defineEmits(['removeTag']);
const { tagClasses } = useTagInputClasses('default');

const trucatedTitle = computed(() => {
  if (props.title.length > 100) return `${props.title.slice(0, 100)}...`;
  return props.title;
});
</script>
