<template>
  <div class="flex flex-col my-4">
    <div
      class="flex justify-between items-center border-b pb-3 border-secondary-300">
      <div class="flex gap-2 grow font-simplerBold text-md">
        <span>יעד:</span>
        <div v-html="model.title"></div>
      </div>
      <div
        class="gap-1 w-[251px] xl:w-[328px] text-xs xl:text-sm 2xl:text-base">
        <BaseInput
          v-model="model.estimation"
          size="sm"
          variant="task"
          placeholder="כמה זמן יקח לך להשיג את המטרה?"
          :disabled="!editable">
          <template #prefix>
            <StopWatch class="w-8 h-full" />
          </template>
        </BaseInput>
      </div>
    </div>
    <div class="flex gap-3 items-stretch mt-5">
      <div class="flex flex-col items-stretch flex-1 h-full">
        <p class="font-simplerBold">פעולות:</p>
        <TagsInput
          v-model="model.tasks"
          height="160px"
          :color="color"
          :disabled="!editable"/>
      </div>
      <div class="flex flex-col items-stretch flex-1">
        <p class="font-simplerBold">משאבים:</p>
        <TagsInput
          v-model="model.resources"
          height="160px"
          :color="color"
          :disabled="!editable"/>
      </div>
      <div class="flex flex-col items-stretch flex-1">
        <p class="font-simplerBold">מדדים:</p>
        <TagsInput
          v-model="model.criteria"
          height="160px"
          :color="color"
          :disabled="!editable"/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { StopWatch } from '@/index';
import BaseInput from '@/components/Input/BaseInput.vue';
import TagsInput from '@/components/TagsInput/TagsInput.vue';
import type { ITaskModel } from '@/types/interfaces';
import type { PropType } from 'vue';
import { useVModel } from '@vueuse/core';
import type { TagType } from '@/components/TagsInput/types';

const props = defineProps({
  modelValue: {
    type: Object as PropType<ITaskModel>,
    default: () => {},
  },
  color: {
    type: String as PropType<TagType>,
    default: 'default',
  },
  editable: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue', emit, {
  passive: true,
  deep: true,
});
</script>
